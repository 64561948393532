
const toggleTip = (e, data) => {
    e.preventDefault();
    var isopen = document.getElementById(e.currentTarget.id).getElementsByClassName("tip-description")[0].style.display;
    console.log("clicked" + e.currentTarget.id);
    for (let index = 1; index <= 6; index++) {
        const element = document.getElementById(index);
        if (index != e.currentTarget.id) {
            element.getElementsByClassName("tip-description")[0].style.display = "none";
            element.getElementsByClassName("collapse-button")[0].innerHTML = "+";
        } else {
            if (index == e.currentTarget.id && isopen == "block") {
                element.getElementsByClassName("tip-description")[0].style.display = "none";
                element.getElementsByClassName("collapse-button")[0].innerHTML = "+";
            } else {
                element.getElementsByClassName("tip-description")[0].style.display = "block";
                element.getElementsByClassName("collapse-button")[0].innerHTML = "-";
            }
        }
        
    }
};

const Tips = () => {
    return (
        <div className='tips-container'>
            <div className='tips-container-header'>
                <h2>Hasznos tanácsok</h2>
            </div>
            <div className='tip-list'>
                <div className='tip-element' id="1" onClick={toggleTip}>
                    <div className='tip-title'>
                        <div>Milyen gyakran érdemes átnézetni az automataváltót?</div>
                        <div className='collapse-button'>+</div>
                    </div>
                    <div className='tip-description' style={{display: 'none'}}>
                        Ajánlott minden 60,000 kilométerenként vagy évente (amelyik előbb bekövetkezik), illetve tulajdonos váltás után szervizeltetni az automataváltót, hogy optimális állapotban maradjon.
                    </div>
                </div>
                <div className='tip-element'  id="2" onClick={toggleTip}>
                    <div className='tip-title'>
                        <div>Milyen jelek utalnak arra, hogy ideje lenne szervizeltetni a váltót?</div>
                        <div className='collapse-button'>+</div>
                    </div>
                    <div className='tip-description' style={{display: 'none'}}>
                        Ha nehézkes váltást, rendellenes zajokat észlelsz, vagy a váltóolaj színe sötétedik, itt az ideje egy szakértői átvizsgálásnak, illetve, ha nem vagy tisztában, hogy jól működik-e az automataváltód.
                    </div>
                </div>
                <div className='tip-element'  id="3" onClick={toggleTip}>
                    <div className='tip-title'>
                        <div>Hogyan hosszabbítható meg egy automata váltó élettartama?</div>
                        <div className='collapse-button'>+</div>
                    </div>
                    <div className='tip-description' style={{display: 'none'}}>
                        Rendszeres olajcserével, a megfelelő olaj használatával, és elkerülve a túlterhelést, jelentősen meghosszabbítható a váltó élettartama. A rendszeres átvizsgálás és szervizelés is nagyban hozzájárul az élettartam meghosszabításához.
                    </div>
                </div>
                <div className='tip-element'  id="4" onClick={toggleTip}>
                    <div className='tip-title'>
                        <div>Milyen előnyökkel jár az időszakos karbantartás?</div>
                        <div className='collapse-button'>+</div>
                    </div>
                    <div className='tip-description' style={{display: 'none'}}>
                        Az időszakos karbantartás növeli a jármű megbízhatóságát, javítja a teljesítményt, és hosszú távon pénzt takarít meg a megelőzéssel. Fordulj hozzánk bizalommal, a hosszú évek tapasztalata által elsajátított hozzáértés és szakmai tapasztalatnak köszönhetően megfelelő magyarázatot adunk szavakban is, és ha szerviz alá kerül a váltó, szakszerűen le lesz szervizelve.
                    </div>
                </div>
                <div className='tip-element'  id="5" onClick={toggleTip}>
                    <div className='tip-title'>
                        <div>Mi történik egy szerviz során?</div>
                        <div className='collapse-button'>+</div>
                    </div>
                    <div className='tip-description' style={{display: 'none'}}>
                        A szerviz során ellenőrizzük a váltóolaj állapotát, cseréljük az olajat és szűrőt, valamint átvizsgáljuk a váltót, hogy minden tökéletesen működjön. Mikor a váltó leszerelésre kerül, minden egyes mozzanatról fényképek, illetve esetlegesen videók készülnek ügyfél kérésre. Szükség esetén élő videóban közvetítjük a folyamatokat.
                    </div>
                </div>
                <div className='tip-element'  id="6" onClick={toggleTip}>
                    <div className='tip-title'>
                        <div>Leszerelve érkezhet váltó?</div>
                        <div className='collapse-button'>+</div>
                    </div>
                    <div className='tip-description' style={{display: 'none'}}>
                        Fogadunk leszerelt váltót tapasztalt szerelőktől illetve szervizektől, de ezeket csak azután, hogy előzőekben szervizünket kellőképpen tájékoztatták. Ilyen esetben is mindenképpen szükséges a telefonos egyeztetés!
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tips;