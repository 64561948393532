
const ServiceTable = () => {
    return (
        <div className='services-table-container'>
                <div className='service-container'>
                    <img src='/images/knowledge_icon.png' />
                    <label className='service-header'>30 éves tapasztalat</label>
                    <text>Tapasztalatunknak köszönhetően már rengeteg problémával találkoztunk és oldottuk meg őket. Így minden bizonnyal a Te váltódon is tudunk segíteni!
                    Így már azt is tudjuk, hogy minden váltó máshogy hibásodik meg, ezért árajánlatot csak személyes egyeztetés után tudunk adni. Meg kell értened, hogy kívülről senki nem lát bele a váltóba, ezért amíg szét nincs szerelve, a pontos árat és a pontos hibát meghatározni nem tudjuk.</text>
                </div>
                <div className='service-container'>
                    <img src='/images/technical_device.png' />
                    <label className='service-header'>Automata váltók teljeskörű javítása</label>
                    <text>A több évtizedes munkánk során lehetőségünk volt megismerni a legapróbb és legritkább, valamint a legnagyobb és a leggyakrabb hibákat, és azokra kidolgozni a megfelelő javítási technikákat.</text>
                </div>
                <div className='service-container'>
                    <img src='/images/transmission_icon.png' />
                    <label className='service-header'>Innovatív megoldások és folyamatos fejlesztés</label>
                    <text>Szervizünk folyamatosan követi az autóipari technológiai újításokat, és bevezeti a legmodernebb megoldásokat. Mindig arra törekszünk, hogy a legjobb szolgáltatást nyújtsuk, és minden problémára megtaláljuk a leghatékonyabb megoldást.</text>
                </div>
                <div className='service-container'>
                    <img src='/images/warranty_icon.png' />
                    <label className='service-header'>Rövid határidő, garancia</label>
                    <text>Munkáinkat rövid határidőre, és természetesen garanciával vállaljuk. Szükség esetén autómentőt biztosítunk!</text>
                </div>
                <div className='service-container'>
                    <img src='/images/wrench_icon.png' />
                    <label className='service-header'>Kizárólag gyári minőségű alkatrészek</label>
                    <text>Mi hosszútávban gondolkodunk! Nem az a célunk, hogy minél hamarabb újra találkozzunk, hanem az, hogy ha problémád van a váltóddal, mi jussunk az eszedbe. Ezért kiemelt figyelmet fordítunk a beszerelt alkatrészek minőségére is! A jó alkatrészek szakszerű beszereléséhez hozzátartozik a szakmailag meghatározott idő is!</text>
                </div>
            </div>
    );
}

export default ServiceTable;