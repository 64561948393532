const ContactFailed = () => {
    return (
        <div className='contact-result-container'>
            <div className='contact-result-header-failed'>
                <h1>Sikertelen kapcsolatfelvétel!</h1>
            </div>
            <div className='contact-result-message'>
                Valami hiba történt a kapcsolatfelvétel során, kérjük próbálkozz újra később!
            </div>
            <div className='contact-result-button-container'>
                <a className='hover-button' href='/contact'>Vissza a kapcsolat oldalra</a>
            </div>
        </div>
    )
};

export default ContactFailed;