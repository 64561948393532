import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const ContactForm = () => {
    const form = useRef();
    const navigate = useNavigate();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
          .sendForm('service_i3is6c8', 'template_h1a53am', form.current, {
            publicKey: 'MSB3ZJjnpX0XdBL_P',
          })
          .then(
            () => {
              navigate("/contact/successful");
              console.log('SUCCESS!');
            },
            (error) => {
              navigate("/contact/failed");
              console.log('FAILED...', error.text);
            },
          );
      };

    return (
        <form ref={form} className="contact-form-component" onSubmit={sendEmail}>
            <div className="contact-element">
                <label>Név <span>*</span></label>
                <input type="text" name='from_name' required/>
            </div>
            <div className="contact-element">
                <label>E-mail <span>*</span></label>
                <input type="email" name='reply_to' required/>
            </div>
            <div className="contact-phone">
                <label>Telefon <span>*</span></label>
                <input type="tel" name='from_phone' required/>
            </div>
            <div className="contact-element">
                <label>Tárgy <span>*</span></label>
                <input type="text" name='from_title' required/>
            </div>
            <div className="contact-element">
                <label>Miben segíthetünk? <span>*</span></label>
                <textarea name='from_message' required/>
            </div>
            <input className="form-send-button" type="submit" />
        </form>
    )
}

export default ContactForm;