
const LocationMap = () => {
    return (
        <div className="location-map-container">
            <h1>Hol talál meg minket?</h1>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5610.156232718772!2d18.403196948645768!3d47.20177703315004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4769f64a6239826d%3A0x21f32b2eea2d0d09!2zU3rDqWtlc2ZlaMOpcnbDoXIsIE1hbG9tIHUuIDQzLCA4MDAw!5e0!3m2!1shu!2shu!4v1723887029820!5m2!1shu!2shu" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
}

export default LocationMap;