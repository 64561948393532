import '../../css/main.css';
import ContactForm from '../contact/contact_form';

const ServicesPage = () => {
    return (
        <div>
            <div className='conatact-page-header'>
                <h1>Szolgáltatásaink</h1>
            </div>
            <div className="services_header">
                <div className="services_header_content">
                    <text>
                        Európai és amerikai autók automata váltóinak teljeskörű javításával és felujításával állunk Ügyfeleink rendelkezésére.
                    </text>
                    <div className="services_brand_images">
                        <img src="/images/brands/audi.png"/>
                        <img src="/images/brands/bmw.png"/>
                        <img src="/images/brands/fiat.png"/>
                        <img src="/images/brands/mercedes.png"/>
                        <img src="/images/brands/porsche.png" />
                        <img src="/images/brands/skoda.png"/>
                        <img src="/images/brands/mini.png"/>
                        <img src="/images/brands/volvo.png"/>
                        <img src="/images/brands/vw.png"/>
                        <img src="/images/brands/ford.png"/>
                    </div>
                </div>
            </div>
            <div className="services_columns">
                <div className="services_left_cloumn">
                    <div className='servicel_left_column_img_container'>
                        <img src="/images/bmw7.jpg"/>
                    </div>
                    <div className='services_left_column_content'>
                        <h1>Automata váltó szervíz</h1>
                        <div className='services_left_column_content_text'>Szervizünk az alábbi szolgáltatásokat nyújtja - akár hozott, kiszerelt váltók esetében is:</div>
                        <text className='important-text'>
                            Váltóhibás autóját ha szükség van rá műhelyünkbe szállítjuk,ha van rá módunk csere autót biztosítunk.
                        </text>
                        <ul>
                            <li>Automata váltó szervíz</li>
                            <li>Diagnosztika és hiba feltárás</li>
                            <li>Automata váltó olaj és szűrő cseréje</li>
                            <li>Automata váltó javítás</li>
                            <li>Automata váltók teljeskörű felujítása</li>
                            <li>Konverter-turbina javítás, tisztítás</li>
                            <li>Automata váltók beépítet és periférikus alkatrészeinek javítása</li>
                            <li>Wandler javítás</li>
                            <li>Hidraulikus vezérlőegységek javítása</li>
                            <li>Automata váltók és alkatrészek beszerelése</li>
                        </ul>
                    </div>
                </div>
                <div className="services_right_column">
                    <ContactForm />
                </div>
            </div>
        </div>
    );
}

export default ServicesPage;