import {
    createBrowserRouter
} from 'react-router-dom';

import Homepage from './shared/homepage';
import ServicesPage from './shared/servicespage';
import GalleryPage from './shared/gallerypage';
import WarrantyPage from './shared/warrantypage';
import ContactPage from './shared/contactpage';
import Pricingpage from './shared/pricingpage';
import ContactSuccessful from './contact/contact-successful';
import ContactFailed from './contact/contact-failed';

const router = createBrowserRouter([
    { path: '/', element: <Homepage />},
    { path: '/services', element: <ServicesPage />},
    { path: '/gallery', element: <GalleryPage />},
    { path: '/pricing', element: <Pricingpage />},
    { path: '/warranty', element: <WarrantyPage />},
    { path: '/contact', element: <ContactPage />},
    { path: '/contact/successful', element: <ContactSuccessful />},
    { path: '/contact/failed', element: <ContactFailed />}
])

export default router;