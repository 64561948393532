import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import router from './components/router';

//import components
import MenuBar from './components/shared/menubar';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import Footer from './components/shared/footer';



const App = () => {
  return (
    ReactDOM.createRoot(document.getElementById('root')).render(
      <div className='website-body'>
        <MenuBar />
        <RouterProvider router={router} />
        <Footer />
      </div>
    )
  );
}


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
