import { useLocation } from 'react-router-dom';
import '../../css/menu_bar.css';

const MenuBar = () => {

    var ismenuopen = false;
    var opennavstyle = {
        display: 'none'
    };
    var closednavstyle = {
        display: 'flex'
    };

    const toggleMenu = (e) => {
        e.preventDefault();
        
        if (ismenuopen) {
            console.log("ismenuopen igen > nem");
            ismenuopen = false;
            document.getElementsByClassName("navigation-buttons-container-mobile-open")[0].style.display = 'none';
            document.getElementsByClassName("navigation-buttons-container-mobile-closed")[0].style.display = 'flex';
        } else {
            console.log("ismenuopen nem > igen");
            ismenuopen = true;
            document.getElementsByClassName("navigation-buttons-container-mobile-open")[0].style.display = 'flex';
            document.getElementsByClassName("navigation-buttons-container-mobile-closed")[0].style.display = 'none';
        }
    }

    const location = window.location.pathname;
    var homebutton, servicesbutton, gallerybutton, pricingbutton, warrantybutton, contactbutton;

    if (location == '/') {
        homebutton = <a href='/' className='active-site-button'>Bemutatkozás</a>
    } else {
        homebutton = <a href='/'>Bemutatkozás</a>
    }

    if (location == '/services') {
        servicesbutton = <a href='/services' className='active-site-button'>Szolgáltatásaink</a>
    } else {
        servicesbutton = <a href='/services'>Szolgáltatásaink</a>
    }

    if (location == '/gallery') {
        gallerybutton = <a href='/gallery'className='active-site-button'>Galéria</a>
    } else {
        gallerybutton = <a href='/gallery'>Galéria</a>
    }

    if (location == '/pricing') {
        pricingbutton = <a href='/pricing'className='active-site-button'>Áraink</a>
    } else {
        pricingbutton = <a href='/pricing'>Áraink</a>
    }

    if (location == '/warranty') {
        warrantybutton = <a href='/warranty'className='active-site-button'>Garancia</a>
    } else {
        warrantybutton = <a href='/warranty'>Garancia</a>
    }

    if (location == '/contact') {
        contactbutton = <a href='/contact'className='active-site-button'>Kapcsolat</a>
    } else {
        contactbutton = <a href='/contact'>Kapcsolat</a>
    }

    return (
        <div className='header-bar'>
            <div className='logo-container'>
                <img alt='Automata Váltó Felujítás Hungary Kft.' src='/images/image1_alt.webp' />
                <div>
                    <label className='company-name-label'>Automata Váltó Felujítás Hungary Kft.</label>
                    <label className='slogen-label'>Automata váltók szakszerű javítása, felújítása és karbantartása</label>
                </div>
            </div>
            <div className='navigation-buttons-container-desktop'>
                {homebutton}
                {servicesbutton}
                {gallerybutton}
                {pricingbutton}
                {warrantybutton}
                {contactbutton}
            </div>
            <div className='navigation-buttons-container-mobile-open' style={opennavstyle}>
                <a className='menu-close-button' href='javascript:void(0)' onClick={toggleMenu}>Menü bezárása</a>
                {homebutton}
                {servicesbutton}
                {gallerybutton}
                {pricingbutton}
                {warrantybutton}
                {contactbutton}
            </div>
            <div className='navigation-buttons-container-mobile-closed' style={closednavstyle}>
                <a className='menu-close-button' href='javascript:void(0)' onClick={toggleMenu}>Menü</a>
            </div>
        </div>
    )
}

const MenuBarOld = () => {
    return (
        <div className='header-bar'>
            <div className='logo-container'>
                <label className='company-name-label'>Automata Váltó Felujítás Hungary Kft.</label>
                <img alt='Automata Váltó Felujítás Hungary Kft.' src='/logo192.png' />
            </div>
            <label className='slogen-label'>Automata váltók szakszerű javítása, felújítása és karbantartása</label>
            <div className='navigation-buttons-container'>
                <a href='/'>Bemutatkozás</a>
                <a href='/services'>Szolgáltatásaink</a>
                <a href='/gallery'>Galéria</a>
                <a href='/warranty'>Garancia</a>
                <a href='/contact'>Kapcsolat</a>
            </div>
        </div>
    )
}

export default MenuBar