
const ServiceNumbers = () => {
    return (
        <div className="service-number-component">
            <div className="service-number-component-header">
                <h2>Szervizünk számokban</h2>
            </div>
            <div className="service-numbers">
                <div className="service-number-container">
                    <div className="service-number-count">
                        620<sup>+</sup>
                    </div>
                    <div className="service-number-description">
                        Elégedett ügyfél
                    </div>
                </div>
                <div className="service-number-container">
                    <div className="service-number-count">
                        1.000<sup>+</sup>
                    </div>
                    <div className="service-number-description">
                        Megjavított váltó
                    </div>
                </div>
                <div className="service-number-container">
                    <div className="service-number-count">
                        1.432
                    </div>
                    <div className="service-number-description">
                        Elfogyasztott kávé
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ServiceNumbers;