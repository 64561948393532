import '../../css/main.css';
import ContactForm from '../contact/contact_form';
import LocationMap from './locationmap';

const ContactPage = () => {
    return (
        <div>
            <div className='conatact-page-header'>
                <h1>Kapcsolat</h1>
            </div>
            <div className='top-contact-container'>
                <div>
                    Keressen<br />minket!
                </div>
                <div className='contact-cards'>
                    <div className='contact-card'>
                        <label className='contact-name'>Wittmann László</label>
                        <label className='contact-position'>Tulajdonos</label>
                        <a className='contact-phone-number' href='tel:+36707700035'>+36-70/770-0035</a>
                        <a href='mailto:automatavaltofelujitashungary@gmail.com' className='contact-email'>automatavaltofelujitashungary@gmail.com</a>
                    </div>
                    <div className='contact-card-alt'>
                        <label className='contact-name'>Katona Jácint</label>
                        <label className='contact-position'>Tulajdonos</label>
                        <a className='contact-phone-number' href='tel:+36304924217'>+36-30/492-4217</a>
                        <a href='mailto:automatavaltofelujitashungary@gmail.com' className='contact-email'>automatavaltofelujitashungary@gmail.com</a>
                    </div>
                </div>
            </div>
            <div className='bottom-contact-container'>
                <div>
                    Írjon<br />nekünk!
                </div>
                <div>
                    <div className='contact-page-form-container'>
                        <ContactForm />
                    </div>
                </div>
            </div>
            <div className='opening-hours-contact-container'>
                <div>
                    Nyitvatartásunk
                </div>
                <div>
                    <div className='opening-hours-data'>
                        <div className='opening-day'>
                            <div className='opening-day-name'>Hétfő</div>
                            <div className='opening-day-hours'>8:00 - 16:00</div>
                        </div>
                        <div className='opening-day'>
                            <div className='opening-day-name'>Kedd</div>
                            <div className='opening-day-hours'>8:00 - 16:00</div>
                        </div>
                        <div className='opening-day'>
                            <div className='opening-day-name'>Szerda</div>
                            <div className='opening-day-hours'>8:00 - 16:00</div>
                        </div>
                        <div className='opening-day'>
                            <div className='opening-day-name'>Csütörtök</div>
                            <div className='opening-day-hours'>8:00 - 16:00</div>
                        </div>
                        <div className='opening-day'>
                            <div className='opening-day-name'>Péntek</div>
                            <div className='opening-day-hours'>8:00 - 16:00</div>
                        </div>
                        <div className='opening-day'>
                            <div className='opening-day-name'>Szombat</div>
                            <div className='opening-day-hours'>Zárva</div>
                        </div>
                        <div className='opening-day'>
                            <div className='opening-day-name'>Vasárnap</div>
                            <div className='opening-day-hours'>Zárva</div>
                        </div>
                    </div>
                </div>
            </div>
            <LocationMap />
            <div className='company-data-container'>
                <div className='company-data-container-header'>
                    <h1>Cégadatok</h1>
                </div>
                <div className='company-data-datalist'>
                    <div className='company-data-name'>
                        Automata Váltó Felújítás Hungary Kft.
                    </div>
                    <div className='company-data-address'>
                        <div>Székhely:</div>
                        <div>8000 Székesfehérvár, Malon utca 43.</div>
                    </div>
                    <div className='company-data-tax'>
                        <div>Adószám:</div>
                        <div>32599279-2-07</div>
                    </div>
                    <div className='company-data-tax'>
                        <div>Cégjegyzékszám:</div>
                        <div>07-09-035895</div>
                    </div>
                    <div className='company-data-tax'>
                        <div>Bankszámlaszám:</div>
                        <div>11736066-21499924</div>
                    </div>
                    <div className='company-data-tax'>
                        <div>IBAN szám:</div>
                        <div>HU52117360062149992400000000</div>
                    </div>
                    <div className='company-data-phone'>
                        <div>Tel:</div>
                        <div>+36-70/770-0035 / +36-30/492-4217</div>
                    </div>
                    <div className='company-data-email'>
                        <div>E-mail:</div>
                        <div>automatavaltofelujitashungary@gmail.com</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;