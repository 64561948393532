export const pricingdata = [
        {
            "id" : 1,
            "name" : "DQ 200 mechatronika",
            "price" : "540.000 Ft",
            "description" : "480.000 Ft + beépítés tanítással: 60.000 Ft"
        },
        {
            "id" : 2,
            "name" : "DQ 250 mechatronika",
            "price" : "680.000 Ft",
            "description" : "540.000 Ft + beépítés: 140.000 Ft"
        },
        {
            "id" : 3,
            "name" : "DQ 250 kuplungozás",
            "price" : "440.000 Ft",
            "description" : "gyári kuplunggal: 320.000 Ft + fel-le szerelés: 120.000 Ft"
        },
        {
            "id" : 4,
            "name" : "Audi 0B5 mechatronika",
            "price" : "760.000 Ft",
            "description" : "640.000 Ft + csere olajjal együtt: 120.000 Ft"
        },
        {
            "id" : 5,
            "name" : "Audi 0B5 kuplungozás",
            "price" : "680.000 Ft",
            "description" : "csak kuplung: 460.000 Ft + szerelés olajjal: 220.000"
        },
        {
            "id" : 6,
            "name" : "Audi 0B5 elektromos fokozatkapcsoló csere",
            "price" : "540.000 Ft",
            "description" : "váltó le- és felszerelés, szét- és összeszerelés"
        },
        {
            "id" : 7,
            "name" : "DCT 450 PowerShift mechatronika",
            "price" : "510.000 Ft",
            "description" : "430.000 Ft + csere: 80.000"
        },
        {
            "id" : 8,
            "name" : "ZF6HP26-28-19 mechatronika",
            "price" : "600.000 Ft",
            "description" : "hidraulikus egység szelepekkel: 380.000 Ft + elektronika: 160.000 Ft + olaj szűrővel: 60.000 Ft"
        },
        {
            "id" : 9,
            "name" : "Fiat Freemont 62TE automataváltó",
            "price" : "980.000 Ft",
            "description" : "cseredarabbal felújított: 820.000 Ft + szerelés olajjal: 160.000 Ft"
        },
        {
            "id" : 10,
            "name" : "Aisin Warner szeleptömbök",
            "price" : "350.000 Ft - 700.000 Ft",
            "description" : "Pontos árról típusmegjelőléssel érdeklődj! + csere: 60.000"
        }
    ]

export default pricingdata;