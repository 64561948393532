import '../../css/main.css';
import LocationMap from './locationmap';
import ServiceNumbers from './servicenumbers';
import ServiceTable from './servicetable';
import Tips from './tips';

const Homepage = () => {
    return (
        <div className='homepage-container'>
            <div className='introduction-container'>
                <div className='introduction-image'>
                    
                </div>
                <div className='introduction-text'>
                    <h1>Bemutatkozás</h1><br />
                    <text>Automata sebességváltók javítására, felújítására és karbantartására szakosodott szerviz.<br />
                    30 éves tapasztalattal rendelkező szervízként biztosan meg tudjuk javítani a Te váltódat is!</text><br />
                    <a href='/services' className='hover-button'>Szolgáltatásaink</a>
                </div>
            </div>
            <ServiceTable />
            <ServiceNumbers />
            <Tips />
            <LocationMap />
        </div>
    );
}

export default Homepage;