import React from 'react';
import '../../css/main.css';
import ImageGallery from 'react-image-gallery';

const images = [
    { original: '/images/gallery/img1.jpg'
    },
    {original: '/images/gallery/img2.jpg'
    },
    {original: '/images/gallery/img3.jpg'
    },
    {original: '/images/gallery/img4.jpg'
    },
    {original: '/images/gallery/img5.jpg'
    },
    {original: '/images/gallery/img6.jpg'
    },
    {original: '/images/gallery/img7.jpg'
    },
    {original: '/images/gallery/img8.jpg'
    },
    {original: '/images/gallery/img9.jpg'
    },
    {original: '/images/gallery/img10.jpg'
    },
    {original: '/images/gallery/img11.jpg'
    },
    {original: '/images/gallery/img12.jpg'
    },
    {original: '/images/gallery/img13.jpg'
    },
    {original: '/images/gallery/img14.jpg'
    },
    {original: '/images/gallery/img15.jpg'
    },
    {original: '/images/gallery/img16.jpg'
    },
    {
        renderItem: () => <video src='/videos/small_video_censored.mp4' muted autoPlay loop />,
    }
];

const GalleryPage = () => {
    return (
        <div className='gallery-page-container'>
            <div className='gallery-page-header'>
                <h1>Galéria</h1>
            </div>
            <div className='image-gallery-container'>
                <ImageGallery showPlayButton={false} showIndex={true} showVideo={true} showFullscreenButton={false} items={images} />
            </div>
        </div>
    );
}

export default GalleryPage;