import '../../css/main.css';

const WarrantyPage = () => {
    return (
        <div className='warranty-page-container'>
            <div className='warranty-page-header'>
                <h1>Garancia</h1>
            </div>
            <div className='warranty-page-text-container'>
                <div className='warranty-page-text'>
                    <text>Minden elvégzett automata váltó javításra<br/>
                    garanciát vállalunk!</text>
                </div>
            </div>
            <div className='warranty-page-consultation'>
                <h2>Állapotfelmérés, konzultáció gépjárművével kapcsolatban</h2>
                <a href='/contact' className='hover-button'>Kérje ajánlatunkat</a>
            </div>
        </div>
    );
}

export default WarrantyPage;