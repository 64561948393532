import '../../css/main.css';
import pricingdata from '../../pricing';

const Pricingpage = () => {
    return (
        <div className='warranty-page-container'>
            <div className='warranty-page-header'>
                <h1>Áraink</h1>
            </div>
            <div className='pricing-page-list'>
                {pricingdata.map((item) => {
                    console.log(item);
                    return(
                        <div className='pricing-item' key={item.id}>
                            <div className='pricing-main-data'>
                                <div className='pricing-name'>{item.name}</div>
                                <div className='pricing-price'>{item.price}</div>
                            </div>
                            <div className='pricing-description'>
                                {item.description}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='warranty-page-consultation'>
                <h2>Állapotfelmérés, konzultáció gépjárművével kapcsolatban</h2>
                <a href='/contact' className='hover-button'>Kérje ajánlatunkat</a>
            </div>
        </div>
    );
}

export default Pricingpage;