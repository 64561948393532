

const Footer = () => {
    return (
        <div className='footer-container'>
            <text>© 2024 Automata Váltó Felujítás Hungary Kft. - Minden jog fenntartva.</text>
            <div className='footer-data'>
                <div>Székhely: 8000 Székesfehérvár, Malon utca 43.</div>
                <div>Adószám: 32599279-2-07</div>
            </div>
        </div>
    );
}

export default Footer;